import React from "react";

const ContactThree = () => {
  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Kontaktirajte nas</h4>
                <h1>Nudimo 24/7 podršku svim našim klijentima</h1>
              </div>
              <div className="contact_location_box">
                <div className="contact_location_map">
                  <span className="icon-location"></span>
                  <p>
                    Beograd
                  </p>
                </div>
                <div className="contact_location_map contact_location_call">
                  <span className="icon-contact_call"></span>
                  <p>
                    klimaservisvv@gmail.com <br />
                    060/025 60 31
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2 responsive_mt">
              <form action="#" method="post">
                <input
                  className="half_width input_m_right"
                  type="text"
                  name="name"
                  placeholder="Vaše ime"
                />
                <input
                  className="half_width"
                  type="EMAIL"
                  name="email"
                  placeholder="Email adresa"
                />
                <input type="tel" name="tel" placeholder="Broj telefona" />
                <textarea
                  name="content"
                  id="content"
                  cols="20"
                  rows="5"
                  placeholder="Napišite poruku"
                ></textarea>
                <button className="btn-yellow" value="SUBMIT NOW" disabled>
                  POŠALJITE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactThree;
